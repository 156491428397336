import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';


const FormInput = ({ name, label }) => {

    const { control } = useFormContext();

    return (

        <Grid item xs={12} sm={6}>
            <Controller
                as={TextField}
                defaultValue=""
                control={control}
                fullWidth
                name={name}
                label={label}
                required
          />
      </Grid>

    )
}

export default FormInput;